<template>
    <div>
        <div v-if="spaceUsers.length">
            <div class="d-flex flex-wrap align-center">
                <v-checkbox v-for="(item, index) in Object.keys(userCheckboxBinders)" :key="index" class="mx-4" v-model="userCheckboxBinders[item].status">
                    <template v-slot:label>
                        <div class="d-flex align-center">
                            <v-icon class="mr-1" small color="secondary">{{ userCheckboxBinders[item].icon }}</v-icon>
                            <span class="secondary--text caption text-uppercase">{{ item }}</span>
                        </div>
                    </template>
                </v-checkbox>
                <v-spacer />
                <v-text-field
                    v-model="memberSearch"
                    autofocus
                    label="Filter"
                    solo
                    flat
                    background-color="grey lighten-4"
                    dense
                    hide-details
                    clearable
                    prepend-inner-icon="mdi-filter"
                    class="mr-2"></v-text-field>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="$store.dispatch('spaceStore/fetchSpaceUsers', $route.params.sid)" text icon>
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>refresh</span>
                </v-tooltip>
            </div>
            <v-divider class="mb-5"></v-divider>
            <v-data-iterator :items="filteredUsersByRole">
                <template v-slot:default="props">
                    <div v-for="(item, index) in props.items" :key="item.uid">
                        <v-divider inset v-if="index > 0" style="opacity: 0.25; border-width: thin" class="mb-2" />
                        <v-list-item two-line>
                            <v-row align="center">
                                <v-col cols="1">
                                    <v-list-item-avatar>
                                        <v-icon x-large>person</v-icon>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <span class="font-weight-medium secondary--text mr-2">{{ item.full_name }}</span>
                                            <v-chip x-small v-if="item.is_active" color="success">Active</v-chip>
                                            <v-chip x-small v-else-if="!item.is_active" color="error">Inactive</v-chip>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span class="caption mr-3">{{ item.email }}</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                                <v-col cols="3">
                                    <v-list-item-content>
                                        <v-list-item-subtitle style="width: 30%">
                                            <ul style="list-style-type: none">
                                                <ComponentWithStyledBorder
                                                    v-if="item.space_role === roleTypes.SPACE_ADMIN && userCheckboxBinders.administrators.status">
                                                    <li>
                                                        <div class="d-flex justify-space-between align-center">
                                                            <div class="d-flex align-center text-truncate">
                                                                <v-icon class="mr-1 ml-1">security</v-icon>
                                                                <span class="text-truncate">{{ item.space_name }}</span>
                                                            </div>
                                                            <UserActions
                                                                :uid="item.uid.toString()"
                                                                :ucid="item.ucid.toString()"
                                                                :isActiveUser="item.is_active"
                                                                :userName="item.full_name"
                                                                :hierarchicalLevel="nuvolosObjectTypes.SPACE"
                                                                :showRevokeButton="true"
                                                                :showDeactivateActivateButton="false"
                                                                role="Space Administrator"
                                                                :spaceName="item.space_name"></UserActions>
                                                        </div>
                                                    </li>
                                                </ComponentWithStyledBorder>
                                                <div v-if="item.instance_editor_roles.length && userCheckboxBinders.editors.status">
                                                    <ComponentWithStyledBorder v-for="role in item.instance_editor_roles" :key="role.iid">
                                                        <li>
                                                            <div class="d-flex justify-space-between align-center">
                                                                <div class="d-flex align-center text-truncate">
                                                                    <v-icon v-if="role.role_name === roleTypes.INSTANCE_VIEWER" class="mr-1 ml-1">
                                                                        visibility
                                                                    </v-icon>
                                                                    <v-icon v-else-if="role.role_name === roleTypes.INSTANCE_EDITOR" class="mr-1 ml-1">
                                                                        edit
                                                                    </v-icon>
                                                                    <v-icon v-else-if="role.role_name === roleTypes.INSTANCE_OBSERVER" class="mr-1 ml-1">
                                                                        visibility_off
                                                                    </v-icon>
                                                                    <span class="text-truncate">{{ role.Instance }}</span>
                                                                </div>
                                                                <UserActions
                                                                    :uid="item.uid.toString()"
                                                                    :ucid="item.ucid.toString()"
                                                                    :isActiveUser="item.is_active"
                                                                    :userName="item.full_name"
                                                                    :hierarchicalLevel="nuvolosObjectTypes.INSTANCE"
                                                                    :spaceAdmin="false"
                                                                    :showRevokeButton="true"
                                                                    :showDeactivateActivateButton="false"
                                                                    :instanceId="role.iid.toString()"
                                                                    :instanceName="role.Instance"
                                                                    :role="role.role_name"></UserActions>
                                                            </div>
                                                        </li>
                                                    </ComponentWithStyledBorder>
                                                </div>
                                                <div v-if="item.instance_viewer_roles.length && userCheckboxBinders.viewers.status">
                                                    <ComponentWithStyledBorder v-for="role in item.instance_viewer_roles" :key="role.iid">
                                                        <li>
                                                            <div class="d-flex justify-space-between align-center">
                                                                <div class="d-flex align-center text-truncate">
                                                                    <v-icon v-if="role.role_name === roleTypes.INSTANCE_VIEWER" class="mr-1 ml-1">
                                                                        visibility
                                                                    </v-icon>
                                                                    <v-icon v-else-if="role.role_name === roleTypes.INSTANCE_EDITOR" class="mr-1 ml-1">
                                                                        edit
                                                                    </v-icon>
                                                                    <span class="text-truncate">{{ role.Instance }}</span>
                                                                </div>
                                                                <UserActions
                                                                    :uid="item.uid.toString()"
                                                                    :ucid="item.ucid.toString()"
                                                                    :isActiveUser="item.is_active"
                                                                    :userName="item.full_name"
                                                                    :hierarchicalLevel="nuvolosObjectTypes.INSTANCE"
                                                                    :spaceAdmin="false"
                                                                    :showRevokeButton="true"
                                                                    :showDeactivateActivateButton="false"
                                                                    :instanceId="role.iid.toString()"
                                                                    :instanceName="role.Instance"
                                                                    :role="role.role_name"></UserActions>
                                                            </div>
                                                        </li>
                                                    </ComponentWithStyledBorder>
                                                </div>
                                                <div v-if="item.instance_observer_roles.length && userCheckboxBinders.observers.status">
                                                    <ComponentWithStyledBorder v-for="role in item.instance_observer_roles" :key="role.iid">
                                                        <li>
                                                            <div class="d-flex justify-space-between align-center">
                                                                <div class="d-flex align-center text-truncate">
                                                                    <v-icon class="mr-1 ml-1">visibility_off</v-icon>

                                                                    <span class="text-truncate">{{ role.Instance }}</span>
                                                                </div>
                                                                <UserActions
                                                                    :uid="item.uid.toString()"
                                                                    :ucid="item.ucid.toString()"
                                                                    :isActiveUser="item.is_active"
                                                                    :userName="item.full_name"
                                                                    :hierarchicalLevel="nuvolosObjectTypes.INSTANCE"
                                                                    :spaceAdmin="false"
                                                                    :showRevokeButton="true"
                                                                    :showDeactivateActivateButton="false"
                                                                    :instanceId="role.iid.toString()"
                                                                    :instanceName="role.Instance"
                                                                    :role="role.role_name"></UserActions>
                                                            </div>
                                                        </li>
                                                    </ComponentWithStyledBorder>
                                                </div>
                                                <AssignRolesDialog
                                                    :assignee="item.full_name"
                                                    :instanceData="getTargetInstancesForUserAssign(item.uid)"
                                                    :spaceName="item.space_name"
                                                    :ucid="item.ucid.toString()"
                                                    :spaceRole="item.space_role"></AssignRolesDialog>
                                            </ul>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                            </v-row>
                            <v-list-item-action>
                                <div class="d-flex align-center">
                                    <UserActions
                                        v-if="item.space_role !== roleTypes.SPACE_ADMIN"
                                        :uid="item.uid.toString()"
                                        :ucid="item.ucid.toString()"
                                        :isActiveUser="item.is_active"
                                        :userName="item.full_name"
                                        :hierarchicalLevel="nuvolosObjectTypes.INSTANCE"
                                        :spaceAdmin="false"
                                        :showRevokeButton="false"></UserActions>
                                    <UserActions
                                        v-else-if="item.space_role === roleTypes.SPACE_ADMIN"
                                        :uid="item.uid.toString()"
                                        :ucid="item.ucid.toString()"
                                        :isActiveUser="item.is_active"
                                        :userName="item.full_name"
                                        :hierarchicalLevel="nuvolosObjectTypes.SPACE"
                                        :showRevokeButton="false"></UserActions>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </div>
                    <invite-btn @goToInvite="$emit('goToInvite', { value: true })" />
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning" text class="mt-3">Your search for found no results.</v-alert>
                </template>
            </v-data-iterator>
        </div>
        <div v-else-if="!spaceUsers.length && !fetchingSpaceUsers">
            <v-alert text prominent type="info">
                <div class="d-flex flex-column">
                    <span class="font-weight-bold">No members found</span>
                    <span>
                        Currently this space has no users. You can
                        <v-btn small dark color="info" outlined @click="$emit('goToInvite', { value: true })">
                            <v-icon small>add</v-icon>
                            Invite
                        </v-btn>
                        new members.
                    </span>
                </div>
            </v-alert>
        </div>
        <div v-else-if="fetchingSpaceUsers">
            <div
                v-for="item in [
                    { id: 'c1', opacity: 1 },
                    { id: 'c2', opacity: 0.75 },
                    { id: 'c3', opacity: 0.5 }
                ]"
                :key="item.id"
                class="my-6">
                <div :style="{ opacity: item.opacity }">
                    <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
import InviteBtn from '@/components/InviteBtn.vue'
const UserActions = () => import('@/components/UserActions')
const AssignRolesDialog = () => import('@/modules/space/components/TheSpaceAssignRolesDialog.vue')
const ComponentWithStyledBorder = () => import('@/components/ComponentWithStyledBorder')

export default {
    mixins: [enumsData],
    components: { UserActions, AssignRolesDialog, ComponentWithStyledBorder, InviteBtn },
    data() {
        return {
            search: '',
            memberSearch: '',
            userCheckboxBinders: {
                administrators: { status: true, icon: 'security' },
                editors: { status: true, icon: 'edit' },
                viewers: { status: true, icon: 'visibility' },
                observers: { status: true, icon: 'visibility_off' }
            }
        }
    },
    computed: {
        ...mapState('spaceStore', ['spaceUsers', 'spaceInstances', 'fetchingSpaceUsers']),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        filteredUsersByRole() {
            return this.filteredSpaceUsers.filter(user => {
                if (
                    (this.userCheckboxBinders.administrators.status && user.space_role === this.roleTypes.SPACE_ADMIN) ||
                    (this.userCheckboxBinders.editors.status && user.instance_editor_roles && user.instance_editor_roles.length) ||
                    (this.userCheckboxBinders.viewers.status && user.instance_viewer_roles && user.instance_viewer_roles.length) ||
                    (this.userCheckboxBinders.observers.status && user.instance_observer_roles && user.instance_observer_roles.length)
                ) {
                    return user
                }
                return false
            })
        },
        filteredSpaceUsers() {
            if (!this.memberSearch) {
                return this.spaceUsers
            } else {
                return this.spaceUsers.filter(user => {
                    const email = user.email.toLowerCase()
                    const name = user.full_name.toLowerCase()
                    return name.includes(this.memberSearch.toLowerCase()) || email.includes(this.memberSearch.toLowerCase())
                })
            }
        }
    },
    methods: {
        getTargetInstancesForUserAssign: function (uid) {
            const [spaceUserData] = this.spaceUsers.filter(user => user.uid === uid)
            const userAssignedInstances = spaceUserData.instance_editor_roles.concat(spaceUserData.instance_viewer_roles).reduce((roles, instance) => {
                roles[instance.iid] = instance.role_name
                return roles
            }, {})
            return this.spaceInstances.map(instance => ({
                iid: instance.iid,
                instance_name: instance.long_id,
                selectedRole: userAssignedInstances[instance.iid] || '',
                selectedInstance: null
            }))
        }
    },
    mounted() {
        this.$store.dispatch('spaceStore/fetchSpaceUsers', this.$route.params.sid)
    }
}
</script>
